<template>
  <div class="q-gutter-y-xs">
    <audit-trail-record
      :processId="processId"
      style="padding-left: 0"
      :tender-id="$route.params.id"
    />
    <pro-deck-card :title="getRes('Form.Field.Attachments')" expandable>
      <div v-for="attachment in attachments" :key="attachment.fileId">
        <pro-file :file="attachment" show-download-button />
      </div>
      <div v-if="attachments.length === 0">---</div>
    </pro-deck-card>
  </div>
</template>

<script>
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import AuditTrailRecord from "@/components/PROSmart/AuditTrail/AuditTrailRecord";
import ProFile from "@/components/PROSmart/ProFile";

export default {
  name: "ProQueryAuditLogDetails",
  components: { ProDeckCard, AuditTrailRecord, ProFile },
  props: {
    processId: String,
    attachments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
