<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <div class="q-mb-md text-h4">{{ getRes("Document.Query") }}</div>
      <pro-query-audit-log-details
        :processId="$route.params.processId"
        :attachments="attachments"
      />
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProQueryAuditLogDetails from "@/components/PROSmart/ProQueryAuditLogDetails";

export default {
  components: { ProMenuLayout, ProQueryAuditLogDetails },
  data() {
    return {
      attachments: [],
    };
  },
  created() {
    this.$proSmart.tender
      .getQueryDetails(this, this.$route.params.id, this.$route.params.queryId)
      .then((query) => (this.attachments = query.attachments));
  },
};
</script>
